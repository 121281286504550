import { Component } from "react";
import { Container} from "react-bootstrap";
import Channel from "./Channel";
import "./Channels.scss";
import { colors_corpus } from "./colors.js";


export default class QuickLib extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Libs: [],
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleChangeVideo = this.onHandleChangeVideo.bind(this);

    fetch("Tv.json")
      .then((response) =>
        response.json().then((data) => {
          this.setState({
            Libs: data,
            Selected_chapter: 0,
            Selected_video:0,
            chapter_clicked: 0,
          });
          // console.log(data);
        })
      )
      .catch((error) => console.error(error));
  }

  onHandleChange(e, clicked_id) {
    // console.log(e, clicked_id);

    this.setState({
      Selected_chapter: clicked_id,
    });
    return;
  }

  onHandleChangeVideo(e, clicked_id) {
    // console.log(e, chapter, clicked_id);

    this.setState({
      // Selected: this.state.Libs[clicked_id],
      Selected_video: clicked_id,
    });
    return;
  }

  render() {
    // console.log(this.state.Selected)
    console.log(this.state.Libs)
    let channel;
    if (
      this.state.Selected_chapter !== undefined &&
      this.state.Selected_video !== undefined
    ) {
      console.log(this.state.Libs[this.state.Selected_chapter])
      channel = (
        <Channel
          name={this.state.Libs[this.state.Selected_chapter].name}
          tv={{
            name: this.state.Libs[this.state.Selected_chapter].videos[
              this.state.Selected_video
            ].name,
            src:
              this.state.Libs[this.state.Selected_chapter].videos[
                this.state.Selected_video
              ].video,
          }}
          color={colors_corpus[this.state.Selected_chapter % colors_corpus.length]}
        />
      );
    }
    return (
      <Container fluid className="libcontainer">
        <div className="channels">
          {this.state.Libs.map((channel, idx) => {
            return (
              <div key={idx}>
                <button
                  className={`${
                    idx === this.state.Selected_chapter
                      ? "Bselected"
                      : "Bnoselected"
                  } Button w-100 ml-auto mr-auto`}
                  onClick={(e) => this.onHandleChange(e, idx)}
                  key={idx}
                >
                  {channel.name}
                </button>
                <div
                  key={"d" + idx}
                  className={`${
                    idx === this.state.Selected_chapter ? "visible" : "invisible"
                  }`}
                >
                  {channel.videos.map((video, ivd) => {
                    return (
                      <button
                        key={"K" + ivd}
                        className={`${
                          idx + "_" + ivd ===
                          this.state.Selected_chapter +
                            "_" +
                            this.state.Selected_video
                            ? "Vselected"
                            : "Bnoselected"
                        } Button ButtonVideo ml-5`}
                        onClick={(e) => this.onHandleChangeVideo(e, ivd)}
                      >
                        {video.name}
                      </button>
                    );
                  })}
                </div>
              </div>
             
            );
          })}
        </div>
        
        {channel}
      </Container>
    );
  }
}
