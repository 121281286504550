import { Component } from "react";
import "./Channels.scss";

export default class Channel extends Component {
  render() {
    return (
      <div className="Cardcontainer bordered">
        <div className="card w-100 h-100">
          <div
            className="card-header"
            style={{
              color: this.props.color.color,
              backgroundColor: this.props.color.backgroundcolor,
              textAlign: "center",
            }}
          >
            {/* <a href={this.props.tv.src} target="_blank" rel="noopener noreferrer" >{this.props.tv.name}</a> */}
            {this.props.name}
          </div>

          <div className="card-body">
            <iframe
              className="video"
              allowFullScreen="True"
              allow="autoplay; encrypted-media"
              src={this.props.tv.src}
              title={this.props.tv.name}
            >
              Your browser does not support frames, so you will not be able to
              view this page.
            </iframe>
          </div>
        </div>
      </div>
    );
  }
}
