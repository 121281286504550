import { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import "./Navigation.css";


class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      showAddLib: false
    };
    
  }

  render() {
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="sm"
          variant="dark"
          className="content-fluid fixed-top"
        >
          <Container id="navigation">
            <NavLink
              className="navbar-brand nav-link ml-auto mr-auto pl-5 "
              to="/"
            >
              TV sport
            </NavLink>

          </Container>
        </Navbar>
      </>
    );
  }
}

export default Navigation;

